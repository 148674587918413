import React from 'react'
import diff from 'microdiff';
import { createSchema } from 'genson-js';
import Form from '@rjsf/bootstrap-4';
import validator from '@rjsf/validator-ajv8';
import { Stepper } from "@progress/kendo-react-layout";
import {
  mapMarkerIcon,
  fileProgrammingIcon,
  trackChangesAcceptIcon,
} from "@progress/kendo-svg-icons";
import { MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import { Button } from '@progress/kendo-react-buttons';
import { filterBy } from '@progress/kendo-data-query';

const DEPLOYMENT_ENDPOINT = "https://iac-azfunctions-dev.azurewebsites.net/api/deploy";
const DEPLOYMENT_BUILD_STRING = "deploy";
const DEPLOYMENT_SAVE_STRING = "save";

const stepperSteps = [
  {
    label: "Pick Environment",
    svgIcon: mapMarkerIcon,
  },
  {
    label: "Config Customer",
    svgIcon: fileProgrammingIcon,
  },
  {
    label: "Config Services",
    svgIcon: fileProgrammingIcon,
  },
  {
    label: "Submit",
    svgIcon: trackChangesAcceptIcon,
  },
];

const delay = 200;

const camelToTitleCase = (string) => {
  const result = string.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

function generateUiSchema (schema, fieldsToHide, servicesToHide) {
  let retObj = schema;
  for (var k in schema) {
    if (typeof schema[k] === 'object' && schema[k] !== null) {
        if(servicesToHide?.includes(k)) retObj[k] = {"ui:widget": "hidden", ...schema[k]};
        retObj[k] = {"ui:title": camelToTitleCase(k), ...schema[k]};
        generateUiSchema(retObj[k], fieldsToHide)
    } else if (schema.hasOwnProperty(k)) {
        if(!k.includes(":")) retObj[k] = {"ui:title": camelToTitleCase(k)};
        if(fieldsToHide?.includes(k)) retObj[k] = {"ui:widget": "hidden"};
    }
  }
  return retObj;
}

function EnvPicker({deployments, selectedFile, setSelectedFile, setStepperValue, setConfigJSON, setValuesJSON, setConfigOverwrite, setValuesOverwrite}) {

  const [data, setData] = React.useState(deployments);
  const [loading, setLoading] = React.useState(false);
  const timeout = React.useRef(null);

  React.useEffect(() => {
    setData(deployments)
  }, [JSON.stringify(deployments)])

  const filterData = (filter) => {
    const data = deployments.slice();
    return filterBy(data, filter);
  };

  const filterChange = (event) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setData(filterData(event.filter));
      setLoading(false);
    }, delay);

    setLoading(true);
  }; 
  
  const handleNewEnv = () => {
    setSelectedFile({
      "ID": 0,
      "Environment": "default",
      "Company": "default",
      "Facility": "New Deployment",
      "File": "default"
    });
    setStepperValue((prev) => prev + 1)
  }

  const handleEnvChange = (e) => {
    setSelectedFile(e.value || {});

    setConfigJSON(null);
    setValuesJSON(null);
    setConfigOverwrite(null);
    setValuesOverwrite(null);
  };

  const columns = [
    {
      field: "Company",
      header: "Company",
      width: "200px",
    },
    {
      field: "Facility",
      header: "Facility",
      width: "200px",
    },
    {
      field: "Environment",
      header: "Environment",
      width: "200px"
    },
  ];

  return (
    <div className='px-5 mx-5 pt-5'>

    <h3>Create or edit deployments</h3>
    <p className='pb-4'>Select from a list of prior deployments or <a href='#' className="text-primary" onClick={handleNewEnv}>create a new one</a></p>

    {deployments.length > 0 ? <> 
      <MultiColumnComboBox
        onChange={handleEnvChange}
        data={data}
        columns={columns}
        textField={"Facility"}
        style={{width: "600px"}}
        placeholder="Please select ..."
        defaultValue={selectedFile?.File ? selectedFile : null}
        filterable={true}
        onFilterChange={filterChange}
        loading={loading}
      />
      <br/>
      <Button className="mt-4" themeColor={"primary"} disabled={!selectedFile.File} onClick={() => setStepperValue((prev) => prev + 1)}>
        Next
      </Button>
    </> : <p>Loading...</p>}
    </div>
  )
}

function ConfigCustomer({selectedFile, setStepperValue, configJSON, setConfigOverwrite}) {

  const configSubmit = (e) => {
    setStepperValue((prev) => prev + 1)
    setConfigOverwrite(e.formData)
  }

  let configSchema = createSchema(configJSON);

  let configUiSchema = {
    "infrastructure": {
      "ui:title": "Customer Settings",
      "customerName": {
        "ui:title": "facility"
      }
    },
    "nursecall": {
      "ui:label": false,
      "voalte": {
        "ui:title": "Keycloak Settings",
        "enabled": {
          "ui:disabled": true
        },
        "version": {
          "ui:disabled": !(selectedFile.File === "default")
        }
      }
    }
  }

  return (
    <div className='d-flex justify-content-center'>
      {selectedFile.File ? 
        <div className='w-25 mr-5'>
          <Form
            schema={configSchema}
            validator={validator}
            onSubmit={configSubmit}
            formData={configJSON}
            uiSchema={configUiSchema}
          />
        </div> : <p>Please pick an environment to edit</p>
      }
      
    </div>
  )
}

function ConfigServices({selectedFile, setStepperValue, valuesJSON, setValuesOverwrite}) {

  const configSubmit = (e) => {
    setStepperValue((prev) => prev + 1)
    setValuesOverwrite(e.formData)
  }

  let valuesSchema = createSchema(valuesJSON, {noRequired: true});
  let disabledFields = ["disabled", "version", "aksDeployment"]
  let disabledServices = ["tags", "adminConfiguration", "careTeams", "maestro", "poeWeb", "rolePermissions"]

  let valuesUiSchema = generateUiSchema(structuredClone(valuesJSON), disabledFields, disabledServices)

  return (
    <div className='d-flex justify-content-center'>
      {selectedFile.File ? <>
        <div className='w-25 mr-5'>
          <Form
            schema={valuesSchema}
            validator={validator}
            onSubmit={configSubmit}
            formData={valuesJSON}
            uiSchema={valuesUiSchema}
          />
        </div>
      </> : <p>Please pick an environment to edit</p>
      }
      
    </div>
  )
}

function App() {
  
  const [selectedFile, setSelectedFile] = React.useState({});
  const [stepperValue, setStepperValue] = React.useState(0);
  const [deployments, setDeployments] = React.useState([]);
  
  const [configJSON, setConfigJSON] = React.useState(null);
  const [valuesJSON, setValuesJSON] = React.useState(null);
  
  const [configOverwrite, setConfigOverwrite] = React.useState(null);
  const [valuesOverwrite, setValuesOverwrite] = React.useState(null);

  const getFiles = async () => {
    let response = await fetch(DEPLOYMENT_ENDPOINT)
    let files = await response.json()

    setDeployments(files)
  }

  const getFileData = async () => {
    let response = await fetch(DEPLOYMENT_ENDPOINT + `?config=${selectedFile.File}`)
    let fileData = await response.json()
    
    setConfigJSON(fileData[0]?.ConfigYaml)
    setValuesJSON(fileData[0]?.ValuesYaml)
  }
  
  React.useEffect(() => {
    getFiles();
  }, [])

  React.useEffect(() => {
    if(selectedFile.File) getFileData();
  }, [selectedFile.File])

  const handleChange = (e) => {
    setStepperValue(e.value);
  };
  
  function FinalCheck() {

    const handleSubmit = async (action) => {
      // let response = await fetch(DEPLOYMENT_ENDPOINT, {
      //   method: "POST",
      //   body: JSON.stringify({Action: action, ConfigYaml: configOverwrite, ValuesYaml: valuesOverwrite})
      // })

      // if (response.ok) alert(`Deployment ${action === DEPLOYMENT_BUILD_STRING ? "Started" : "Saved"}!`)

      // window.location.reload();

      console.log("to send", {Action: action, ConfigYaml: configOverwrite, ValuesYaml: valuesOverwrite})
    }

    try {
      let configDiff = diff(configJSON, configOverwrite)
      let valuesDiff = diff(valuesJSON, valuesOverwrite)
    
    return (
      <>
        {selectedFile.File ? 
          <div className='px-5 mx-5 mt-5'>
            <div className='d-flex justify-content-around'>
              <div className='w-25'>
                <h3 className='pb-2'>Everything look good?</h3>
                <p>Pressing the "Deploy" button will kick off the build. At the moment, there is no way to know if the build was successful or not from within the deployment tool. Please click this <a href='https://portal.azure.com'>link</a> to the Microsoft Azure portal to check on the build. <br/> Alternatively, you can choose to save the deployment that you created with the "Save" button and come back at a later time to deploy it. Your saved deployment will pop up on the list when you make a selection on the first step.</p>
                <Button className="mt-3" themeColor={"primary"} onClick={() => handleSubmit(DEPLOYMENT_BUILD_STRING)}>
                  Deploy
                </Button>
                <Button className="mt-3 ml-3" themeColor={"primary"} onClick={() => handleSubmit(DEPLOYMENT_SAVE_STRING)}>
                  Save
                </Button>
              </div>
              <div>
                <h3>
                  Edit Summary
                </h3>
                {configDiff.length === 0 && valuesDiff.length === 0 ? <p>No changes made</p> : null}
                <div className='d-flex pt-3'>
                  {configDiff.length > 0 ? 
                    <div>
                      <p className='h4 mb-3'>
                        Customer Configuration
                      </p>
                      {configDiff.map((edit) => 
                        <div className='mb-4'>
                          <h5>{camelToTitleCase(edit.path.slice(-1)[0])}</h5>
                          <s className='text-danger'>{edit.oldValue === "" ? "None": edit.oldValue.toString()}</s> - <span className='font-weight-bold'>{edit.value.toString()}</span>
                        </div>
                      )}
                    </div> 
                  : null}

                  {valuesDiff.length > 0 ? 
                    <div className={`pl-${configDiff.length > 0 ? 5 : 0}`}>
                      <p className='h4 mb-3'>
                        Service Configurations
                      </p>
                      {valuesDiff.map((edit) => 
                        <div className='mb-4'>
                          <div className='mb-1'>
                            {edit.path.slice(0,-1).map((crumb, index) => <p className="d-inline font-italic">{camelToTitleCase(crumb)} {edit.path.slice(0,-1)[index+1] && "> "}</p>)}
                          </div>
                          <h5>{camelToTitleCase(edit.path.slice(-1)[0])}</h5>
                          <s className='text-danger'>{edit.oldValue === "" ? "None": edit.oldValue.toString()}</s> - <span className='font-weight-bold'>{edit.value.toString()}</span>
                        </div>
                      )}
                    </div>
                  : null}
                </div>
              </div>
            </div>
          </div>
          : 
          <div className='d-flex justify-content-center'>
            <p>Please pick an environment to edit</p>
          </div>
        }
      </>
    )

    } catch (error) {
      console.log("error getting diff", error)
      return (
        <div className='px-5 mx-5 mt-5'>
          <p className='text-center'>Please don't skip the steps and go through both the customer and services configurations</p>
        </div>
      )
    }
  }

  function getComponent(stepperVal) {
    switch (stepperVal) {
      case 0: return <EnvPicker {...{deployments, selectedFile, setSelectedFile, setStepperValue, setConfigJSON, setValuesJSON, setConfigOverwrite, setValuesOverwrite}} />;
      case 1: return <ConfigCustomer {...{selectedFile, setStepperValue, configJSON, setConfigOverwrite}}/>;
      case 2: return <ConfigServices {...{selectedFile, setStepperValue, valuesJSON, setValuesOverwrite}}/>;
      case 3: return <FinalCheck/>;
      default: return <EnvPicker/>
    }
  }

  return (
    <>
    <div className='sticky-top'>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <a className="navbar-brand" href="#">
          <img src="hillrom.png" alt="Logo" className="d-inline-block align-middle" width="40" height="40"/>
          <span className="navbar-title ml-3 font-weight-bold align-middle" style={{fontFamily: 'Proxima'}}>NurseCall Deployment Tool</span>
        </a>
        <div className="collapse navbar-collapse" id="navbarText">
          <span className=" ml-auto">
            Logout
          </span>
        </div>
      </nav>
      
      <div className='bg-white py-4'>
        <Stepper value={stepperValue} onChange={handleChange} items={stepperSteps}/>
      </div>
    </div>

    {getComponent(stepperValue)}

    
    </>
  )
}

export default App